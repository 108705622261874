.drag-container {
    background: url('../../images/pattern-background.png');
    width: 70vw;
    border: solid 4px #454545;
    border-radius: 15px;
    min-height: 65vh;
    box-shadow: 9px 9px 0 #454545;
    display: flex;
    flex-direction: column;
  }
  
  .handle {
    border-bottom: solid 4px #454545;
    padding: 8px;
    display: flex;
    height: 30px;
  }
  
  .buttons-wrap {
    display: flex;
    flex-direction: row;
    gap: 5px;
    position: absolute;
    right: 10px;
    button {
      border-radius: 50%;
      border: solid 4px #454545;
      height: 30px;
      width: 30px;
      background: transparent;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .window-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 22px;
    font-weight: 700;
    color: #454545;
  }
  
  .content-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding: 25px;
    font-weight: 600;
    color: #454545;
  
  }
  .product-top {
    display: flex;
    flex-grow: 1;
    gap: 50px;
    >div {
        width: 50%;
        max-height: 500px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .price {
        font-weight: 400;
        font-size: 26px;
    }
    p {
        font-size: 18px;
    }
    h2 {
        font-size: 26px;
    }
  }

.input-wrap {
  margin-top: 10px;
    span {
        font-size: 18px;
        font-weight: 600;
    }
}

.images-wrapper {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: 0;
    height: 100%;
    scroll-snap-type: y mandatory;
    img {
        height: calc(100% - 7px);
        scroll-snap-align: start;
        object-fit: cover;
        border-bottom: solid 4px #454545;;
    }
}

.images {
    border: solid 4px #454545;
    border-radius: 6px;
    padding-bottom: 4px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    button {
        background: url('../../images/pattern-background.png');
        border: solid 4px #454545;
        border-radius: 7px;
        color: #454545;
        font-size: 20px;
        font-weight: 600;
        padding: 8px 20px;
        margin: 5px 0;
        cursor: pointer;
    }
}