.drag-container-coming {
    background: url('../../images/pattern-background.png');
    width: 55vw;
    max-width: 640px;
    border: solid 4px #454545;
    border-radius: 15px;
    box-shadow: 9px 9px 0 #454545;
    display: flex;
    flex-direction: column;
  }

  .content-wrap-coming {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding: 25px;
    font-weight: 600;
    color: #454545;
    gap: 20px;
    img {
        padding: 0 30px
    }
  }

  .loading-bar {
    width: 100%;
    height: 18px;
    border: solid 3px #454545;
    border-radius: 5px;
  }

  .loading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    span {
        font-weight: 700;
        font-size: 20px;
    }
  }

  .loading-inner {
    height: calc(100% - 4px);
    width: 14%;
    background-color: #454545;
    margin: 2px;
  }

  .loading-bottons-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    button {
        background: url('../../images/pattern-background.png');
        border: solid 4px #454545;
        border-radius: 7px;
        color: #454545;
        font-size: 20px;
        font-weight: 600;
        padding: 5px 10px;
        min-width: 120px;
        margin: 5px 0;
        cursor: pointer;
    }
    .open-button {
        border: 0;
        background: rgba(193,228,224, .5);
        color: #FCF6E8;
    }
  }

  @media only screen and (max-width: 600px) {
    .drag-container-coming  {
      width: 90vw;
    }
    .coming .window-title {
      display: block;
      font-size: 12px;
      max-width: 70vw;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      height: fit-content;
    }
    .handle {
      display: flex;
      align-items: center;
      padding-left: 20px;
    }
  }