.app {
  height: calc(100vh - 40px);
  overflow: hidden;
  padding: 20px;
}

img {
  filter: saturate(10%);
  cursor: pointer;
}

select, input {
  background: url('./images/pattern-background.png');
  border: solid 4px #454545;
  border-radius: 7px;
  color: #454545;
  font-size: 20px;
  font-weight: 600;
  padding: 5px 10px;
  margin: 5px 0;
}
input {
  width: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
}

select {
  max-width: 160px;
}

.button-close {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}