@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

* {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(./images/wallpaper.png);
  background-blend-mode: saturation;
  /* background-repeat: no-repeat;
  background-size: cover; */
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}
::-webkit-scrollbar-track {
  background: url('./images/pattern-background.png');
  margin: 3px;
}
::-webkit-scrollbar-thumb {
  background: url('./images/pattern-background.png');
  border: solid 4px #454545;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: url('./images/pattern-background.png');
}