.drag-container-notifications {
    background: url('../../images/pattern-background.png');
    width: fit-content;
    max-width: 440px;
    border: solid 4px #454545;
    border-radius: 7px;
    box-shadow: 4px 4px 0 #454545;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
  }

  .content-wrap-notifications {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    padding: 15px;
    font-weight: 600;
    color: #454545;
    gap: 20px;
    img {
        padding: 0 30px
    }
    span {
        font-size: 20px;
        font-weight: 700;
    }
    p {
        font-size: 18px;
        margin: 0;
    }
  }

.button-notification {
    background: url('../../images/pattern-background.png');
    border: solid 4px #454545;
    border-radius: 7px;
    color: #454545;
    font-size: 20px;
    font-weight: 600;
    padding: 5px;
    text-transform: uppercase;
    min-width: 80px;
    cursor: pointer;
}

.button-notification:hover {
    background: #454545;
    color:#FCF6E8
}

.notification-top-wrapper {
    display: flex;
    gap: 10px
}