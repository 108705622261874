.drag-container-error {
    background: url('../../images/pattern-background.png');
    width: 45vw;
    max-width: 640px;
    border: solid 4px #454545;
    border-radius: 15px;
    box-shadow: 9px 9px 0 #454545;
    display: flex;
    flex-direction: column;
    position: absolute;
  }

  .content-wrap-error {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding: 25px;
    font-weight: 600;
    color: #454545;
    gap: 20px;
    img {
        padding: 0 30px
    }
    span {
        font-size: 28px;
        font-weight: 700;
    }
    p {
        font-size: 24px;
        margin: 0;
    }
  }

  .error-top-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px
  }

  .error-buttons-wrapper-right {
    display: flex;
    gap: 5px;
  }

  .error-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    gap: 10px;
    button {
        background: url('../../images/pattern-background.png');
        border: solid 4px #454545;
        border-radius: 7px;
        color: #454545;
        font-size: 20px;
        font-weight: 600;
        padding: 5px 10px;
        min-width: 120px;
        margin: 5px 0;
        cursor: pointer;
    }
    button:hover {
      background: #454545;
      color:#FCF6E8
    }
}