.icons-drag {
    pointer-events: none;
}

.icons-drag-area {
    position: absolute;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: 0;
    width: 60px;
    height: 60px;
    padding: 0;
    cursor: pointer;
    img {
        object-fit: cover;
        width: 100%;
    }
    span {
        font-weight: 600;
        color: #454545;
        text-wrap: nowrap;
        font-size: 20px;
    }
}